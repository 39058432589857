import cls from './Terminals.module.scss';
import terminal1 from '../../../assets/image/terminal-1-2.png';
import terminal3 from '../../../assets/image/terminal-3.png';
import terminal4 from '../../../assets/image/terminal-4.png';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';
import { TerminalSwiper } from './Swiper';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';

const Terminals = () => {
  return (
    <div className={cls.Terminals} id="terminals">
      <WidthLimiter>
        <div className={cls.container}>
          <h2>
            Наши платежные <span>терминалы</span>
          </h2>
          <Swiper
            className="mySwiper"
            modules={[Pagination]}
            breakpoints={{
              slidesPerView: 1,
              1024: {
                slidesPerView: 2,
              }
            }}
            pagination={true}
            spaceBetween={50}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <div className={cls.swiperItem}>
                <img src={terminal1} alt="terminalImg" />
                <div className={cls.content}>
                  <h3>Купер</h3>
                  <p>
                    Бюджетная модель для установки внутри помещения. Качественные
                    комплектующие и приемлемая стоимость
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cls.swiperItem}>
                <img src={terminal1} alt="terminalImg" />
                <div className={cls.content}>
                  <h3>Навигатор</h3>
                  <p>
                    Напольная модель класса “Люкс” для установки внутри помещения.
                    Профессиональные функции и повышенная надежность.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cls.swiperItem}>
                <img src={terminal3} alt="terminalImg" />
                <div className={cls.content}>
                  <h3>Спайдер</h3>
                  <p>
                    Нехватка пространства для установки терминала оплаты — не повод
                    отказываться от проходимого места!
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={cls.swiperItem}>
                <img src={terminal4} alt="terminalImg" />
                <div className={cls.content}>
                  <h3>Стритер</h3>
                  <p>
                    Для установки на улице. Терминал прошел испытания в
                    экстремальных погодных условиях Сибири.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </WidthLimiter>
    </div>
  );
};

export default Terminals;
