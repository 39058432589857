import cls from './AboutUs.module.scss';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const AboutUs = () => {
  return (
    <WidthLimiter>
      <div className={cls.AboutUs} id="about-us">
        <h2>
          О <span>нас</span>
        </h2>
        <div className={cls.content}>
          <div className={cls.item}>
            <h3>Платежи в пользу сотовых операторов Кыргызстана</h3>
            <p>
              В 2007 году Mobile & Television communications реализовал проект по
              организации приема платежей в пользу абонентов компании «ОсОО
              "АкТел"»(Fonex).
            </p>
            <p>
              В 2007 – 2012 гг. к проекту присоединились: Megacom, Beeline,
              NurTelecom, Nexi, Katel, SapatCom, SmartTV.На данный момент с нами
              работают примерно 100 суб. агентов и 5 000 платежных киосков.
            </p>
          </div>
          <div className={cls.item}>
            <h3>Платежи в пользу сотовых операторов России и СНГ</h3>
            <p>
              На сегодняшний день компания осуществляет прием платежей в оплату
              услуг операторов: МТС РФ, Билайн РФ, Мегафон РФ, TELE2, НТВ плюс РФ,
              Радуга ТВТаком (БИЛАЙН) Таджикистан, Unitel (БИЛАЙН) Узбекистан,
              ТТ-мобайл (Мегафон) Таджикистан, Вавилон-Мобайл, Таджикистан, БИЛАЙН
              Украина (Украинские радиосистемы), АрменТел Фиксированная Связь,
              АрменТел (БИЛАЙН) Армения, ЗАО “К-Телеком” (МТС, Армения), СООО
              «Мобильные ТелеСистемы»,(МТС, Беларусь), ТОО «КаР-Тел»(БИЛАЙН),
              Казахстан, Мобител(Билайн) Грузия, Tcell (Indigo)Таджикистан.В
              ближайшее время также появится возможность осуществления оплаты за
              коммунальные услуги, платежи в оплату авиа- и ж\д билетов, оплата
              кредитов.
            </p>
          </div>
        </div>
      </div>
    </WidthLimiter>
  );
};

export default AboutUs;
