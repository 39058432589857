import cls from './StatsBlock.module.scss';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const StatsBlock = () => {
  return (
    <div className={cls.StatsBlock}>
      <WidthLimiter>
        <div className={cls.container}>
          <div className={cls.pair}>
            <div className={cls.item}>
              <h4>118 000</h4>
              <p>постоянных пользователей</p>
            </div>
            <div className={cls.item}>
              <h4>8</h4>
              <p>лет бесперебойной работы</p>
            </div>
          </div>
          <div className={cls.pair}>
            <div className={cls.item}>
              <h4>100</h4>
              <p>надежных агентов и партнеров </p>
            </div>
            <div className={cls.item}>
              <h4>5 000</h4>
              <p>платежных терминалов </p>
            </div>
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};

export default StatsBlock;
