import cls from './CatalogBlock.module.scss';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const CatalogBlock = () => {
  return (
    <WidthLimiter>
      <div className={cls.CatalogBlock} id="catalog">
        <h2 className={cls.title}>
          Каталог <span>поставщиков</span>
        </h2>
        <a className={cls.link} href="https://payment.mtc.kg/" target="_blank" rel="noreferrer">Перейти</a>
      </div>
    </WidthLimiter>
  );
};

export default CatalogBlock;
