import cls from './TopBlock.module.scss';
import topImage from '../../../assets/image/topImage.png';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const TopBlock = () => {
  return (
    <WidthLimiter>
      <div className={cls.TopBlock}>
        <div className={cls.container}>
          <div className={cls.left}>
            <h1 className={cls.title}>
              <span>Онлайн</span>
              <br /> оплата услуг
            </h1>
            <p className={cls.text}>
              Оплачивайте более 500 местных и зарубежных услуг без комиссии:
              мобильная связь, интернет, коммунальные услуги, пополнение
              банковских карт и многое другое
            </p>
          </div>
          <div className={cls.right}>
            <img src={topImage} alt="topImage" />
          </div>
        </div>
      </div>
    </WidthLimiter>
  );
};

export default TopBlock;
