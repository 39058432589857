import cls from './Description.module.scss';
import ecoSystemMts from '../../../assets/image/EcoSystemMts.png';
import { WidthLimiter } from '../../UI/WidthLimiter/WidthLimiter';

const Description = () => {
  return (
    <div className={cls.Description}>
      <WidthLimiter>
        <div className={cls.container}>
          <div className={cls.content}>
            <h2>
              Крупнейший <span>оператор платежных систем</span> Кыргызской
              Республики!
            </h2>
            <p>
              Mobile & Television communications – компания по приему платежей в
              оплату услуг операторов сотовой связи. Центральный офис расположен
              по адресу: Кыргызстан, Кант, улица Ленина, 70. Компания была
              основана в 2007 году успешно осуществляет свою основную функцию –
              предоставление своим клиентам и партнерам платежных
              сервисов.«PaySys» - это программный комплекс, позволяющий
              осуществлять моментальные платежи в пользу поставщиков услуг
              Кыргызстана, России и стран СНГ (оплата услуг сотовой связи,
              телефонии, телевидения, и др.)
              <br /> Преимущества программного обеспечения «PaySys» заключаются в
              высоком уровне безопасности и надёжности, высокой скорости (платежи
              через систему «PaySys» осуществляются моментально).
            </p>
          </div>
          <div className={cls.image}>
            <img src={ecoSystemMts} alt="imageSystem" />
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};

export default Description;
