import { useState } from 'react';
import cls from './NavBar.module.scss';
import logoMts from '../../assets/image/Logo.svg';
import { WidthLimiter } from '../UI/WidthLimiter/WidthLimiter';

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onClickBurger = () => {
    setShowMenu((prevState) => !prevState);
  };

  const onCloseMenu = () => {
    setShowMenu(false);
  };

  const menuShowStyle = `${cls.navLinks} ${showMenu ? cls.navLinksShow : ''}`;
  const burgerStyle = `${cls.navBurger} ${showMenu ? cls.burgerClose : ''}`;

  return (
    <header className={cls.NavBar}>
      <WidthLimiter>
        <div className={cls.container}>
          <img src={logoMts} alt="logo" />
          <nav className={menuShowStyle}>
            <ul className={cls.list}>
              <li>
                <a href="#catalog" className={cls.link} onClick={onCloseMenu}>
                  Каталог поставщиков
                </a>
              </li>
              <li>
                <a href="#terminals" className={cls.link} onClick={onCloseMenu}>
                  Платежные терминалы
                </a>
              </li>
              <li>
                <a href="#about-us" className={cls.link} onClick={onCloseMenu}>
                  О нас
                </a>
              </li>
            </ul>
          </nav>
          <button className={burgerStyle} onClick={onClickBurger}></button>
        </div>
      </WidthLimiter>
    </header>
  );
};

export default NavBar;
