import TopBlock from "../../components/landing/TopBlock/TopBlock";
import StatsBlock from "../../components/landing/StatsBlock/StatsBlock";
import CatalogBlock from "../../components/landing/CatalogBlock/CatalogBlock";
import Description from "../../components/landing/Description/Description";
import Terminals from "../../components/landing/Terminals/Terminals";
import AboutUs from "../../components/landing/AboutUs/AboutUs";

const HomePage = () => {
  return (
    <>
      <TopBlock />
      <StatsBlock />
      <CatalogBlock />
      <Description />
      <Terminals />
      <AboutUs />
    </>
  );
};

export default HomePage;
