import React from "react";
import NavBar from "./components/NavBar/NavBar";
import HomePage from "./pages/Home/HomePage";
import Footer from "./components/Footer/Footer";
import "./App.scss";

function App() {
  return (
    <>
      <NavBar />
      <main className="App-content">
        <HomePage />
      </main>
      <Footer />
    </>
  );
}

export default App;
